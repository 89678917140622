
import Vue from 'vue';
import { DateTime } from 'luxon';
import { BDropdown } from 'bootstrap-vue';
import { SunbedModelVueSelect } from '../../../configurations/components';
import { SunbedOrderState, SunbedOrderUtils } from '../../../../../services/sunbeds';
import { OrganizationVueSelect } from '../../../organizations';
import { DatatableFiltersDropdownFilters } from '../../interfaces';
import { DatatableConfig } from '@vedicium/metronic-vue';
import { WeekCalendarUtils, WeekCalendarComponent } from '../../../../../components/week-calendar';

export default Vue.extend({
  name: 'ordersDatatableFiltersDropdown',
  components: {
    WeekCalendarComponent,
    SunbedModelVueSelect,
    OrganizationVueSelect,
  },
  data() {
    return {
      isDropdownHideable: true,
      isApplied: false,

      filters: {
        model: undefined,
        organization: undefined,
        states: [],
        production_week: null,
      } as DatatableFiltersDropdownFilters,

      metadata: {
        production_week: {
          minDate: null,
          maxDate: null,
        },
        states: [
          SunbedOrderState.REQUESTED,
          SunbedOrderState.SCHEDULING_PRODUCTION,
          SunbedOrderState.SCHEDULING_DELIVERY,
          SunbedOrderState.READY_FOR_PRODUCTION,
          SunbedOrderState.IN_PRODUCTION,
          SunbedOrderState.READY_TO_SHIP,
          SunbedOrderState.SHIPPED,
          SunbedOrderState.MODIFICATION_IN_PROCESS,
          SunbedOrderState.CANCELLED,
        ],

        filtersOnShow: undefined as DatatableFiltersDropdownFilters | undefined,
      },

      SunbedOrderUtils,
      DateTime,
    };
  },

  computed: {
    productionWeekText(): string {
      if (!this.filters.production_week) {
        return 'Please select...';
      }

      return WeekCalendarUtils.getHumanReadableISOWeek(this.filters.production_week);
    },
  },

  methods: {
    async onSelectInput() {
      // Disable the ability to hide the dropdown
      // This is because of issues when selection an option which is outside of the dropdown
      this.$set(this, 'isDropdownHideable', false);
      setTimeout(() => this.$set(this, 'isDropdownHideable', true), 400);
    },

    onDatePickerInput(isoWeek: string | null) {
      if (!isoWeek || isoWeek == 'Invalid DateTime') {
        return;
      }

      // Hide dropdown and set production week
      (this.$refs['select:production_week:dropdown'] as BDropdown).hide();
      this.$set(this.filters, 'production_week', isoWeek);

      this.onSelectInput();
    },
    onStateCheckbox(state: SunbedOrderState, value: boolean): void {
      switch (value) {
        case true: {
          if (this.filters.states.includes(state) === true) {
            return;
          }

          this.filters.states.push(state);
          break;
        }

        case false:
        default: {
          this.$set(
            this.filters,
            'states',
            this.filters.states.filter((row) => row !== state, []),
          );
        }
      }
    },

    onApplyButtonClick(): void {
      this.$set(this, 'isApplied', true);

      // Send a filter change
      this.onFilterChange();

      // Hide dropdown
      (this.$refs['dropdown'] as BDropdown).hide();
    },
    onResetButtonClick(): void {
      this.$set(this, 'isApplied', true);

      // Reset all filters
      this.$set(this.filters, 'model', undefined);
      this.$set(this.filters, 'organization', undefined);
      this.$set(this.filters, 'states', []);
      this.$set(this.filters, 'production_week', null);

      // Send a filter change
      this.onFilterChange();

      // Hide dropdown
      (this.$refs['dropdown'] as BDropdown).hide();
    },
    onFilterChange(): void {
      // Create filters
      const filters: DatatableConfig['filters'] = {
        'configuration.model.guid|eq': this.filters.model?._meta.guid,
        'organization.guid|eq': this.filters.organization?._meta.guid,
        'state|eq': this.filters.states.join(',') || undefined,
        'schedule.production_week|eq': this.filters.production_week || undefined,
      };

      this.$emit('change', filters);
    },

    onDropdownShow(): void {
      this.$set(this, 'isApplied', false);
      this.$set(this.metadata, 'filtersOnShow', Object.assign({}, this.filters));
    },
    onDropdownHide(e: Event): void {
      if (this.isDropdownHideable === false) {
        e.preventDefault();
        return;
      }

      // If fitlers are not applied, revert to filters on show
      if (this.isApplied === false) {
        this.$set(this, 'filters', this.metadata.filtersOnShow);
        return;
      }
    },
  },
});
