export const CONFIGURATOR_UV_CONFIGURATIONS_MODAL_EVENT =
  'modal:orders:configurator:uv-configurations';

export const CONFIGURATOR_UV_CONFIGURATIONS_MODAL_OPEN = `${CONFIGURATOR_UV_CONFIGURATIONS_MODAL_EVENT}:open`;
export const CONFIGURATOR_UV_CONFIGURATIONS_MODAL_CLOSE = `${CONFIGURATOR_UV_CONFIGURATIONS_MODAL_EVENT}:close`;

export const CONFIGURATOR_CONFLICT_ERROR_MODAL_EVENT = 'modal:orders:configurator:conflict-error';

export const CONFIGURATOR_CONFLICT_ERROR_MODAL_OPEN = `${CONFIGURATOR_CONFLICT_ERROR_MODAL_EVENT}:open`;
export const CONFIGURATOR_CONFLICT_ERROR_MODAL_CLOSE = `${CONFIGURATOR_CONFLICT_ERROR_MODAL_EVENT}:close`;

export const UPDATE_ORDER_NUMBER_MODAL_EVENT = 'modal:orders:update-order-number';
export const UPDATE_ORDER_NUMBER_MODAL_OPEN = `${UPDATE_ORDER_NUMBER_MODAL_EVENT}:open`;
export const UPDATE_ORDER_NUMBER_MODAL_CLOSE = `${UPDATE_ORDER_NUMBER_MODAL_EVENT}:close`;

export const SUNBED_ORDER_PRODUCTION_REMARK_MODAL_EVENT = 'modal:orders:production-remark';
export const SUNBED_ORDER_PRODUCTION_REMARK_MODAL_OPEN = `${SUNBED_ORDER_PRODUCTION_REMARK_MODAL_EVENT}:open`;
export const SUNBED_ORDER_PRODUCTION_REMARK_MODAL_CLOSE = `${SUNBED_ORDER_PRODUCTION_REMARK_MODAL_EVENT}:close`;

export const SUNBED_ORDER_INTERNAL_REMARK_MODAL_EVENT = 'modal:orders:internal-remark';
export const SUNBED_ORDER_INTERNAL_REMARK_MODAL_OPEN = `${SUNBED_ORDER_INTERNAL_REMARK_MODAL_EVENT}:open`;
export const SUNBED_ORDER_INTERNAL_REMARK_MODAL_CLOSE = `${SUNBED_ORDER_INTERNAL_REMARK_MODAL_EVENT}:close`;

export const SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_EVENT =
  'modal:orders:generate-serial-number';
export const SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_OPEN = `${SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_EVENT}:open`;
export const SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_CLOSE = `${SUNBED_ORDER_GENERATE_SERIAL_NUMBER_MODAL_EVENT}:close`;

export const SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_EVENT =
  'modal:orders:print-identification-label';
export const SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_OPEN = `${SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_EVENT}:open`;
export const SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_CLOSE = `${SUNBED_ORDER_PRINT_IDENTIFICATION_LABEL_MODEL_EVENT}:close`;
