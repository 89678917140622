import {
  ColorPartConfigurationID,
  FilterPartConfigurationID,
  ManufacturingSublineFilterDomain,
  ManufacturingSublineFilterType,
  ManufacturingSublineMode,
  ManufacturingSublineType,
  OptionPartConfigurationID,
} from '../../../parts';

export default {
  uv_types: {
    text: 'UV-type',

    types: {
      '0': '-',
      '2': '2 (≤ 0,3 W/m2)',
      '3': '3 (≤ 0,3 W/m2)',
      '5': '5',
    },
  },

  manufacturing: {
    sublines: {
      types: {
        [ManufacturingSublineType.DEFAULT]: 'Default',
        [ManufacturingSublineType.NON_INVENTORY]: 'Non-inventory',
        [ManufacturingSublineType.ACCESSORY]: 'Accessory',
      },
      modes: {
        [ManufacturingSublineMode.SELECTED_BY_DEFAULT]: "Adhere parent's mode",
        [ManufacturingSublineMode.INCLUDED_BY_DEFAULT]: 'Included by default',
      },

      filters: {
        domains: {
          [ManufacturingSublineFilterDomain.COLOR]: 'Color',
          [ManufacturingSublineFilterDomain.OPTION]: 'Option',
          [ManufacturingSublineFilterDomain.COUNTRY]: 'Country',
        },

        types: {
          [ManufacturingSublineFilterType.OR]: 'OR',
          [ManufacturingSublineFilterType.AND]: 'AND',
          [ManufacturingSublineFilterType.NOT_OR]: 'NOT OR',
          [ManufacturingSublineFilterType.NOT_AND]: 'NOT AND',
        },
      },
    },
  },

  light_sources: {
    types: {
      low_pressure: 'Low pressure',
      low_pressure_facial: 'Low pressure (facial)',
      high_pressure: 'High pressure',
      LED: 'LED',
    },
  },
  options: {
    types: {
      standard: 'Standard',
      power: 'Power option',
      light: 'Light source',
    },

    configuration_ids: {
      // Luxura Jewel
      [OptionPartConfigurationID.LUXURA_JEWEL_UVLED_BOSS_BOOSTER]: {
        name: 'UV-LED Boss-Booster',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_UVLED_SHOULDER_TANNER]: {
        name: 'UV-LED Shoulder tanner',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_UVLED_FACE_TANNER]: {
        name: 'UV-LED Face tanner',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_SOUNDAROUND_PLUS]: {
        name: 'SoundAround Plus',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_WIRELESS_PHONE_CHARGER]: {
        name: 'Wireless Phone Charger',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_CLIMATE_CONTROL]: {
        name: 'Climate Control',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_SENS]: {
        name: 'Sens',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_COMPACT_AIR_EXHAUST]: {
        name: 'Compact Air Exhaust',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_AIR_EXHAUST_TOWER]: {
        name: 'Air Exhaust Tower',
        category: 'Luxura Jewel',
      },
      [OptionPartConfigurationID.LUXURA_JEWEL_AIR_EXHAUST_TOWER_SYSTEM_WITH_WARS]: {
        name: 'Air Exhaust Tower system with W.A.R.S.',
        category: 'Luxura Jewel',
      },
      // Luxura Vegaz
      [OptionPartConfigurationID.LUXURA_VEGAZ_UVLED_TANNING_BOOSTER]: {
        name: 'UV-LED Tanning Booster',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_EXTENSION]: {
        name: 'Shoulder Tanner + SoundAround Plus',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_COMPACT_AIR_EXHAUST]: {
        name: 'Compact Air Exhaust',
        category: 'Luxura Vegaz',
      },

      [OptionPartConfigurationID.LUXURA_VEGAZ_AIR_EXHAUST_TOWER]: {
        name: 'Air exhaust Tower',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_AIR_EXHAUST_WARS]: {
        name: 'Air exhaust Tower System with W.A.R.S.',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_QSENS_XSENS_III]: {
        name: 'Qsens & Xsens III',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_CLIMATE_CONTROL]: {
        name: 'Climate Control',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_LUXURY_FRONT_PANEL]: {
        name: 'Luxury Front Panel',
        category: 'Luxura Vegaz',
      },
      [OptionPartConfigurationID.LUXURA_VEGAZ_SOUND_PLUS]: {
        name: 'Sound Plus',
        category: 'Luxura Vegaz',
      },

      // Luxura X-line
      [OptionPartConfigurationID.LUXURA_X_AMBIENT_FLOWLIGHT_EYELINER]: {
        name: 'Ambient FlowLight Eyeliners',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_AIRCO]: {
        name: 'Airco',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_PREWIRING]: {
        name: 'Pre-wiring for Options after market',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_PREWIRING_38]: {
        name: 'Pre-wiring for Options after market 38',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_INOX_DECOSHIELD_BRANDSHIELD]: {
        name: 'Inox steel polished Decoshield & Brandshield',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_SOUNDAROUND_PLUS]: {
        name: 'SoundAround Plus',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_XSENS_III]: {
        name: 'Xsens III',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_QSENS]: {
        name: 'Qsens',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_AMBIENT_FLOWLIGHT_CONNECT_LINE]: {
        name: 'Ambient FlowLight Connect line',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_LUXURY_FRONT_PANEL]: {
        name: 'Luxury Front Panel with Brandshield and Ambient FlowLight',
        category: 'Luxura X-line',
      },
      [OptionPartConfigurationID.LUXURA_X_AMBIENT_FLOWLIGHT_CENTRE_LINE]: {
        name: 'Ambient FlowLight Centre line',
        category: 'Luxura X-line',
      },

      // Luxura V-line
      [OptionPartConfigurationID.LUXURA_V_TWIN_COLOUR_GOLD]: {
        name: 'Twin Colour Gold',
        category: 'Luxura V-line',
      },
      [OptionPartConfigurationID.LUXURA_V_QSENS]: {
        name: 'Qsens',
        category: 'Luxura V-line',
      },
      [OptionPartConfigurationID.LUXURA_V_XSENS]: {
        name: 'Xsens',
        category: 'Luxura V-line',
      },
      [OptionPartConfigurationID.LUXURA_V_SOUNDAROUND_PLUS]: {
        name: 'SoundAround Plus',
        category: 'Luxura V-line',
      },
      [OptionPartConfigurationID.LUXURA_V_WALNUT_WOODEN_HANDLE]: {
        name: 'Walnut Wooden Handle',
        category: 'Luxura V-line',
      },

      // Other
      [OptionPartConfigurationID.MYLUXURA]: {
        name: 'MyLuxura',
        category: 'Other',
      },
    },
  },
  colors: {
    configuration_ids: {
      [ColorPartConfigurationID.LUXURA_VEGAZ_GOLD]: {
        name: 'Gold',
        category: 'Luxura Vegaz',
      },
      [ColorPartConfigurationID.LUXURA_VEGAZ_SILVER]: {
        name: 'Silver',
        category: 'Luxura Vegaz',
      },
      [ColorPartConfigurationID.LUXURA_VEGAZ_RED]: {
        name: 'Red',
        category: 'Luxura Vegaz',
      },
      [ColorPartConfigurationID.LUXURA_VEGAZ_CRYSTAL_WHITE]: {
        name: 'Crystal White',
        category: 'Luxura Vegaz',
      },
    },
  },

  filters: {
    configuration_ids: {
      [FilterPartConfigurationID.BPS]: 'BPS',
      [FilterPartConfigurationID.HPS]: 'HPS',
      [FilterPartConfigurationID.IPS]: 'IPS',
      [FilterPartConfigurationID.HIGHBRID]: 'Highbrid',
    },
  },
};
