import { OrganizationEntity } from '@vedicium/vue-core';
import { OrganizationInformationEntity } from '../../organizations';
import {
  ColorPartConfigurationID,
  ColorPartEntity,
  FilterPartConfigurationID,
  LightSourcePart,
  OptionPartConfigurationID,
  OptionPartEntity,
  OptionPartPowerConsumption,
  PackagePartEntity,
} from '../../parts/interfaces';
import { SunbedModelEntity } from './sunbed_model.entity';
import {
  SunbedModelTypeEntity,
  SunbedModelTypeFacetannerFilterRow,
  SunbedModelTypeRatedVoltage,
  SunbedModelTypeUVConfiguration,
} from './sunbed_model_type.entity';

export interface SunbedConfigurationSearchResult extends SunbedModelEntity {
  types: Array<SunbedModelTypeEntity>;
}

export interface SunbedConfigurationTypeRatedVoltage extends SunbedModelTypeRatedVoltage {
  purchase_price: number | undefined;
  retail_selling_price: number | undefined;
}

export type SunbedConfigurationTypeOption = SunbedModelTypeEntity['parts']['options'][number] & {
  description: OptionPartEntity['description'];
  type: OptionPartEntity['type'];
  article_number: OptionPartEntity['article_number'];
  purchase_price: number | undefined;
  retail_selling_price: number | undefined;
  acrylic_thickness?: number;

  power_consumption?: Array<OptionPartPowerConsumption>;

  configuration_id: OptionPartConfigurationID | undefined;

  /**
   * Light source information.
   * If option type is 'light', a light source will be available.
   */
  light_source?: (LightSourcePart & { amount: number }) | undefined;

  /**
   * Filter information.
   * If option type is 'light', a filter will be added.
   */
  filter?:
    | {
        description: string;
        article_number: string;
        power: number;
        configuration_id?: string;
      }
    | undefined;

  /**
   * This will be false when the option is from a package,
   * and isn't available for loose sale.
   */
  available: boolean;
};

export type SunbedConfigurationTypeColor = SunbedModelTypeEntity['parts']['colors'][number] & {
  description: ColorPartEntity['description'];
  display_colors: ColorPartEntity['display_colors'];
  article_number: ColorPartEntity['article_number'];
  purchase_price: number | undefined;
  retail_selling_price: number | undefined;
  configuration_id: ColorPartConfigurationID | undefined;
};

export type SunbedConfigurationTypePackage = SunbedModelTypeEntity['parts']['packages'][number] & {
  description: PackagePartEntity['description'];
  options: PackagePartEntity['options'];
  article_number: PackagePartEntity['article_number'];
  purchase_price: number | undefined;
  retail_selling_price: number | undefined;
};

export type SunbedConfigurationTypeUVConfiguration = SunbedModelTypeUVConfiguration & {
  facetanner?: Omit<SunbedModelTypeUVConfiguration['facetanner'], 'filters'> & {
    light_source?: SunbedModelTypeUVConfiguration['facetanner']['light_source'] & {
      description?: string;
    };

    filters?: Array<
      SunbedModelTypeFacetannerFilterRow & {
        description: string;
        configuration_id: FilterPartConfigurationID | undefined;
      }
    >;
  };

  light_sources: Array<
    SunbedModelTypeUVConfiguration['light_sources'][number] & {
      description: string;
    }
  >;

  purchase_price: number | undefined;
  retail_selling_price: number | undefined;
};

export interface SunbedConfigurationType extends SunbedModelTypeEntity {
  rated_voltages: Array<SunbedConfigurationTypeRatedVoltage>;

  // Light source descriptions will be added.
  uv_configurations: Array<SunbedConfigurationTypeUVConfiguration>;

  parts: {
    // Color description & display colors will be added.
    colors: Array<SunbedConfigurationTypeColor>;

    // Option description & type added
    options: Array<SunbedConfigurationTypeOption>;

    // Package description & options added
    packages: Array<SunbedConfigurationTypePackage>;
  };
}

export interface SunbedConfigurationSession {
  organization?: OrganizationEntity;
  organization_information?: OrganizationInformationEntity;

  country?: string;
  language?: string;
  smartvoice_language?: number;
  uv_type?: string;
  rated_voltage?: string;

  live_pricing?: boolean;

  model?: SunbedConfigurationSearchResult;
  type?: SunbedModelTypeEntity;

  configuration: {
    type?: SunbedConfigurationType;
    rated_voltage?: SunbedConfigurationTypeRatedVoltage;
    uv_configuration?: SunbedConfigurationType['uv_configurations'][number];
    color?: SunbedConfigurationType['parts']['colors'][number];
    packages: Array<{
      guid: string;
      article_number: string;
    }>;
    options: Array<{
      guid: string;
      article_number: string;
      configuration_id: OptionPartConfigurationID | undefined;
      package?: string;
    }>;

    amount: number;
    custom_price?: number;
    reference?: string;
    remark?: string;
  };

  desired_delivery_week: 'asap' | string;
}

/**
 * Conflict error
 */
export type SunbedConfigurationConflictType =
  | 'together_with'
  | 'together_with:remove'
  | 'not_together_with';

export type SunbedConfigurationConflictAction = 'add' | 'remove';

export type SunbedConfigurationConflictProperty =
  | {
      type: 'option';
      entity: SunbedConfigurationTypeOption;
    }
  | {
      type: 'package';
      entity: SunbedConfigurationTypePackage;
    };

export class SunbedConfigurationSessionError extends Error {
  constructor(message?: string) {
    super(message || 'Create a SunbedConfigurationSession first');
  }
}

export class SunbedConfigurationConflictError extends Error {
  /**
   * Conflict type that triggered the error.
   */
  type: SunbedConfigurationConflictType;

  /**
   * The conflict action that triggered the error.
   */
  action: SunbedConfigurationConflictAction;

  /**
   * Property that caused the conflict error.
   */
  property: SunbedConfigurationConflictProperty;

  /**
   * Conflicts that were found.
   */
  conflicts: Array<SunbedConfigurationConflictProperty>;

  constructor(
    type: SunbedConfigurationConflictType,
    action: SunbedConfigurationConflictAction,

    property: SunbedConfigurationConflictProperty,
    conflicts: Array<SunbedConfigurationConflictProperty>,

    message?: string,
  ) {
    super(message || 'Combination is not allowed.');

    this.type = type;
    this.action = action;

    this.property = property;
    this.conflicts = conflicts;
  }
}
